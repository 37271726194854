import React from 'react'

const About = () => {
  return (
    <>
    <div className="professional-services section-bg d-none d-md-block" data-background="assets/img/gallery/section_bg04.jpg"></div>
        <div className="profession-caption">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-8">
                        <div className="section-tittle profession-details">
                          <h3 style={{ color: 'red' }}>Welcome to Rudransh Industries</h3>
                          <h3>where creativity meets craftsmanship!</h3>
                          <p>With years of experience in the interior design industry, we specialize in transforming spaces with our custom ceilings, POP designs, and complete interior solutions. Whether you're looking to add elegance to your living room with a stylish false ceiling or seeking to revamp your entire home with modern interiors, we&apos;re here to turn your vision into reality.</p>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="professional-services section-bg d-none d-md-block" data-background="assets/img/gallery/section_bg04.jpg"></div>
        <div className="profession-caption">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-8">
                        <div className="section-tittle profession-details">                          
                          <h3>Our Expertise</h3>
                          <h4>Ceilings</h4>
                           <p>Ceilings are often an overlooked element, but they have the power to dramatically transform a room's ambiance. We offer a variety of ceiling designs, from sleek and minimalistic to elaborate and artistic, ensuring that your space reflects your personal style. Our false ceilings, tray ceilings, and recessed lighting solutions add depth, dimension, and character to any room.</p>

                           <h4>POP</h4>
                           <p>Our POP work is known for its precision and attention to detail. POP is a versatile material that allows us to create unique architectural elements, including decorative moldings, ceiling medallions, and wall designs. Whether you're after a classical look or a modern finish, our POP designs add a touch of sophistication to any interior.</p>
                           
                           <h4>Complete Interior Solutions</h4>
                           <p>At <strong>Rudransh Industries</strong>, we provide end-to-end interior solutions that encompass design, planning, and execution. From residential projects to commercial spaces, our team works closely with you to ensure that every detail is perfect. We focus on creating functional spaces that blend aesthetics with comfort and utility.</p>
                          
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    </>
  )
}

export default About
