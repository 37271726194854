import React from 'react'
import OurInfo from '../components/OurInfo';
import MoreAboutUs from '../components/MoreAboutUs';
import ProfessionalServices from '../components/ProfessionalService';
import PhotoGallery from '../components/PhotoGallery';
import DirectorSection from '../components/DirectorSection';
import Testimonials from '../components/Testimonials';
import Brands from '../components/Brands';
import ContactUs from '../components/ContactUs';
import Blog from '../components/Blog';

const Home = () => {
  return (
    <>
        <OurInfo/>
        <MoreAboutUs/>
        <ProfessionalServices/>
        <PhotoGallery/>
        {/* <DirectorSection/> */}
        <Testimonials/>
        {/* <Brands/> */}
        <ContactUs/>
        <Blog/>
    </>
  )
}

export default Home
