import React from 'react'
import { Link } from 'react-router-dom'
import SliderBanner from '../Banner'

const Header = () => {
  return (
    <div>
      <header>
      <div className="header-area header-transparent">
          <div className="main-header header-sticky">
              <div className="container-fluid">
                  <div className="menu-wrapper d-flex align-items-center justify-content-between">
                      <div className="logo">                        
                          <Link to="/">
                          {/* <img src="images/logo.png" alt=""/> */}
                          <div>
                            <img 
                                src='/images/rudransh_logo.png' 
                                alt='logo' 
                                style={{ height: '80px', width: '130px'}}
                            />
                          </div>
                          </Link>
                      </div>
                      <div className="main-menu f-right d-none d-lg-block">
                          <nav>
                              <ul id="navigation">
                                  <li><Link to="/" style={{ textDecoration:'none' }}>Home</Link></li>
                                  <li><Link to="/about" style={{ textDecoration:'none' }}>About</Link></li>
                                  <li><Link to="/product" style={{ textDecoration:'none' }}>Product</Link></li>
                                  <li><Link to="/gallery" style={{ textDecoration:'none' }}>Gallery</Link></li>
                                  <li><Link to="/company" style={{ textDecoration:'none' }}>Company</Link>
                                      <ul className="submenu">
                                          <li><a style={{ textDecoration:'none' }} href="blog.html">Blog</a></li>
                                          <li><a style={{ textDecoration:'none' }} href="blog_details.html">Blog Details</a></li>
                                          <li><a style={{ textDecoration:'none' }} href="elements.html">Elements</a></li>
                                      </ul>
                                  </li>
                                  <li><Link to="/contact" style={{ textDecoration:'none' }}>Contact</Link></li>
                              </ul>
                          </nav>
                      </div>
                      <div className="header-btns d-none d-lg-block f-right">
                          <a href="#" className="btn header-btn">Contact Us</a>
                      </div>
                      <div className="col-12">
                          <div className="mobile_menu d-block d-lg-none"></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </header>
  <SliderBanner/>
    </div>
  )
}

export default Header
