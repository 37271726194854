import React from 'react'

const SliderBanner = () => {
  return (
    <div className="slider-area">
        <div className="slider-active dot-style">
            <div className="single-slider slider-height hero-overly d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="hero__caption">
                                <span data-animation="fadeInLeft" data-delay=".4s">Welcome to the Intorior World</span>
                                <h1 data-animation="fadeInLeft" data-delay=".6s">Modern Interior &amp; Design</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="single-slider slider-height hero-overly d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="hero__caption">
                                <span data-animation="fadeInLeft" data-delay=".4s">Welcome to Intorior</span>
                                <h1 data-animation="fadeInLeft" data-delay=".6s">Modern Interior &amp; Design</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
        {/* <div className="video-icon">
            <a className="popup-video btn-icon" href="https://www.youtube.com/watch?v=1aP-TXUpNoU"><i className="fas fa-play"></i></a>
        </div> */}
    </div>
  )
}

export default SliderBanner
