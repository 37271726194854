import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './views/Home';
import About from './views/About';
import Product from './views/Product';
import Gallery from './views/Gallery';
import Company from './views/Company';
import Contact from './views/Contact';
// import AdminLogin from './views/admin/login';
// import Dashboard from './views/admin/Dashboard';
// import ProductList from './views/admin/Product_list';

function App() {
  const path = window.location.href;
  const splitPath = path.split('/');
  const url = splitPath[3];

  return (
    <BrowserRouter>
      {
        url != 'admin' && (
          <Header />        
        )
      }
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/product' element={<Product />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/company' element={<Company />} />
        <Route path='/contact' element={<Contact />} />
        {/* ADMIN */}
        {/* <Route path='/admin' element={<AdminLogin />} />
        <Route path='/admin/dashboard' element={<Dashboard />} />
        <Route path='/admin/product-list' element={<ProductList />} /> */}
      </Routes>
      {
        url != 'admin' && (        
        <Footer />
        )
      }
      </BrowserRouter>
  );
}

export default App;
