import React from 'react'

const Company = () => {
  return (
    <div>
      Company
    </div>
  )
}

export default Company
