import React from 'react'

const OurInfo = () => {
  return (
    <div className="our-info-area pt-170 pb-100 section-bg">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className="single-info mb-60">
                        <div className="info-caption">
                            <h4>Clean and Services</h4>
                            <p>For each project we establish relationships with partners who we know will help us. </p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className="single-info mb-60">
                        <div className="info-caption">
                            <h4>Clean and Modern</h4>
                            <p>For each project we establish relationships with partners who we know will help us. </p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className="single-info mb-60">
                        <div className="info-caption">
                            <h4>Clean and Modern</h4>
                            <p>For each project we establish relationships with partners who we know will help us. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OurInfo
