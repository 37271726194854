import React from 'react'

const ProfessionalServices = () => {
  return (
    <>
        <div className="services-area section-padding3">
        <div className="container">
            <div className="row justify-content-center">
                <div className="cl-xl-7 col-lg-8 col-md-10">
                    <div className="section-tittle text-center mb-70">
                        <span>Our Professional Services</span>
                        <h2>Best Interitor Services</h2>
                    </div> 
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="single-services mb-200">
                        <div className="services-img">
                            <img src="images/services1.png" alt=""/>
                            </div>
                            <div className="services-caption">
                            <h3><a href="services.html">Lighting</a></h3>
                            <p className="pera1">For each project we establish </p>
                            <p className="pera2">For each project we establish relationships with partners who we know will help us. </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="single-services mb-200">
                        <div className="services-img">
                            <img src="images/services2.png" alt=""/>
                            </div>
                            <div className="services-caption">
                            <h3><a href="services.html">Interior Design</a></h3>
                            <p className="pera1">For each project we establish </p>
                            <p className="pera2">For each project we establish relationships with partners who we know will help us. </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="single-services mb-200">
                        <div className="services-img">
                            <img src="images/services3.png" alt=""/>
                            </div>
                            <div className="services-caption">
                            <h3><a href="services.html">Office Decoretion</a></h3>
                            <p className="pera1">For each project we establish </p>
                            <p className="pera2">For each project we establish relationships with partners who we know will help us. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ProfessionalServices
