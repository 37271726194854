import React from 'react'

const PhotoGallery = () => {
  return (
    <>
        <div className="gallery-area">
            <div className="container-fluid p-0 fix">
                <div className="row">
                    <div className="col-xl-6 col-lg-4 col-md-6">
                        <div className="single-gallery mb-30">
                            <div className="gallery-img" style={{backgroundImage: `url('images/interior/1314.jpeg')`}}></div>
                            <div className="thumb-content-box">
                                <div className="thumb-content">
                                    <h3><span>Intorior</span>Burj Khalifa</h3>
                                    <a href="work.html"><i className="fas fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="single-gallery mb-30">
                            <div className="gallery-img" style={{backgroundImage: `url('images/interior/7.jpeg')`}}></div>
                            <div className="thumb-content-box">
                                <div className="thumb-content">
                                    <h3><span>Intorior</span>Burj Khalifa</h3>
                                    <a href="work.html"><i className="fas fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="single-gallery mb-30">
                                <div className="gallery-img" style={{backgroundImage: `url('images/interior/25.jpeg')`}}></div>
                            <div className="thumb-content-box">
                                <div className="thumb-content">
                                    <h3><span>Intorior</span>Burj Khalifa</h3>
                                    <a href="work.html"><i className="fas fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="single-gallery mb-30">
                            <div className="gallery-img" style={{backgroundImage: `url('images/interior/25.jpeg')`}}></div>
                            <div className="thumb-content-box">
                                <div className="thumb-content">
                                    <h3><span>Intorior</span>Burj Khalifa</h3>
                                    <a href="work.html"><i className="fas fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="single-gallery mb-30">
                            <div className="gallery-img" style={{backgroundImage: `url('images/interior/3.jpeg')`}}></div>
                            <div className="thumb-content-box">
                                <div className="thumb-content">
                                    <h3><span>Intorior</span>Burj Khalifa</h3>
                                    <a href="work.html"><i className="fas fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-4 col-md-6">
                        <div className="single-gallery mb-30">
                            <div className="gallery-img" style={{backgroundImage: `url('images/interior/12.jpeg')`}}></div>
                            <div className="thumb-content-box">
                                <div className="thumb-content">
                                    <h3><span>Intorior</span>Burj Khalifa</h3>
                                    <a href="work.html"><i className="fas fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default PhotoGallery
