import React from 'react'
import { Link } from 'react-router-dom'

const MoreAboutUs = () => {
  return (
    <>
    <div className="professional-services section-bg d-none d-md-block" data-background="assets/img/gallery/section_bg04.jpg"></div>
      <div className="profession-caption">
          <div className="container">
              <div className="row align-items-end">
                  <div className="col-lg-8">
                        <div className="section-tittle profession-details">
                            <h3 style={{ color: 'red' }}>Welcome to Rudransh Industries</h3>
                            <h3>where creativity meets craftsmanship!</h3>
                            <p>With years of experience in the interior design industry, we specialize in transforming spaces with our custom ceilings, POP designs, and complete interior solutions. Whether you're looking to add elegance to your living room with a stylish false ceiling or seeking to revamp your entire home with modern interiors, we&apos;re here to turn your vision into reality.</p>                          
                        </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <Link to='/about' className="btn btn3">Discover More About us</Link>
                      {/* <a href="about.html" className="btn btn3">Discover More About us</a> */}
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default MoreAboutUs
