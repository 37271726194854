import React from 'react'

const Testimonials = () => {
  return (
    <>
        <div className="testimonial-area testimonial-padding">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-8 col-lg-8 col-md-10">
                        <div className="h1-testimonial-active dot-style">
                            <div className="single-testimonial text-center">
                                <div className="testimonial-caption ">
                                    <div className="testimonial-top-cap">
                                        <img src="images/testi-logo.png" alt=""/>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                    </div>
                                    <div className="testimonial-founder">
                                        <div className="founder-img">
                                            <span><strong>Christine Eve</strong>   -   Co Founder</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="single-testimonial text-center">
                                <div className="testimonial-caption ">
                                    <div className="testimonial-top-cap">
                                        <img src="images/testi-logo.png" alt=""/>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                    </div>
                                    <div className="testimonial-founder  ">
                                        <div className="founder-img">
                                            <span><strong>Christine Eve</strong>   -   Co Founder</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Testimonials
