import React from 'react'

const Footer = () => {
  return (
    <div>
       <footer>
        <div className="footer-area footer-bg">
            <div className="container">
                <div className="footer-top footer-padding">
                    <div className="row d-flex justify-content-between">
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8">
                            <div className="single-footer-caption mb-50">
                                <div className="footer-logo">                                
                                    <img 
                                        src='/images/rudransh_logo.png' 
                                        alt='logo' 
                                        style={{ height: '80px', width: '130px'}}
                                    />
                                </div>
                                <div className="footer-tittle">
                                    <div className="footer-pera">
                                        <p className="info1">Grabbing the concsumer’s attention isn’t enough you have to keep that attention for at least a.</p>
                                    </div>
                                </div>
                                <div className="footer-number">
                                    <h4><span>+91 </span>8154872605</h4>
                                    <p>youremail@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-2 col-md-5 col-sm-6">
                            <div className="single-footer-caption mb-50">
                                <div className="footer-tittle">
                                    <h4>Useful Links</h4>
                                </div>
                                <div className="footer-cap">
                                    <span>Mehsana</span>
                                    <p>8/A Sarthak Hastak Nugar Modhera Road, Mehsana</p>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-7">
                            <div className="single-footer-caption mb-50">
                                <div className="footer-tittle">
                                    <h4>Instagram Feed</h4>
                                </div>
                                <div className="instagram-gellay">
                                    <ul className="insta-feed">
                                        <li><a href="#"><img src="images/instagram1.png" alt=""/></a></li>
                                        <li><a href="#"><img src="images/instagram2.png" alt=""/></a></li>
                                        <li><a href="#"><img src="images/instagram3.png" alt=""/></a></li>
                                        <li><a href="#"><img src="images/instagram4.png" alt=""/></a></li>
                                        <li><a href="#"><img src="images/instagram5.png" alt=""/></a></li>
                                        <li><a href="#"><img src="images/instagram6.png" alt=""/></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-xl-9 col-lg-8">
                            <div className="footer-copy-right">
                                <p></p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4">
                            <div className="footer-social f-right">
                                <span>Follow Us</span>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.facebook.com/sai4ull"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fas fa-globe"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
  )
}

export default Footer
