import React ,{ useState} from 'react'

const ContactUs = () => {

    const [isFormVisible, setIsFormVisible] = useState(false);

    const toggleForm = () => {
        setIsFormVisible(!isFormVisible);
    };

  return (
    <>
       <section className="wantToWork-area w-padding2">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-xl-8 col-lg-8 col-md-8">
                        <div className="wantToWork-caption wantToWork-caption2">
                            <h2>Are you Searching For a First-Class Consultant?</h2>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3">
                        <button className="btn btn-black f-right" onClick={toggleForm}>Contact Us</button>
                    </div>
                </div>
                <div className={`contact-form ${isFormVisible ? 'visible' : ''}`}>
                <form>
                    <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" className="form-control" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" className="form-control" />
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
                </div>
            </div>
        </section>
    </>
  )
}

export default ContactUs
